<template>
    <div v-if="active" class="fixed z-50 inset-0 overflow-y-auto">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity" @click="close()">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
            <div id="modal" class="inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                <div class="modal-content">

                    <modal-close @close="close()"/>
                    <div class="mb-2" v-show="error_auth === true">{{$t('translations.components.modal-manager-login.not-auth')}}</div>

                    <form class="mt-10" @submit.prevent="onSubmit" autocomplete="off">
                        <div class="default-input mb-4">
                            <input placeholder="Pincode" readonly v-model="pincode" name="pincode" type="password" required class="@error('terminal_pincode') border-red-500 @enderror" />
                        </div>

                        <div class="grid grid-cols-3 mt-1 gap-1">
                            <span class="h-10 key col-span-1 inline-flex rounded-sm  shadow-sm">
                                <a @click="setInput('7')" class="button button-blur button-wide">
                                    7
                                </a>
                            </span>
                            <span class="h-10 key col-span-1 inline-flex rounded-sm  shadow-sm">
                                <a @click="setInput('8')" class="button button-blur button-wide">
                                    8
                                </a>
                            </span>
                            <span class="h-10 key col-span-1 inline-flex rounded-sm  shadow-sm">
                                <a @click="setInput('9')" class="button button-blur button-wide">
                                    9
                                </a>
                            </span>
                        </div>
                        <div class="grid grid-cols-3 mt-1 gap-1">
                            <span class="key inline-flex rounded-sm  shadow-sm">
                                <a @click="setInput('4')" class="button button-blur button-wide">
                                    4
                                </a>
                            </span>
                            <span class="key inline-flex rounded-sm  shadow-sm">
                                <a @click="setInput('5')" class="button button-blur button-wide">
                                    5
                                </a>
                            </span>
                            <span class="key inline-flex rounded-sm  shadow-sm">
                                <a @click="setInput('6')" class="button button-blur button-wide">
                                    6
                                </a>
                            </span>
                        </div>

                        <div class="grid grid-cols-3 mt-1 gap-1">
                            <span class="key inline-flex rounded-sm  shadow-sm">
                                <a @click="setInput('1')" class="button button-blur button-wide">
                                    1
                                </a>
                            </span>
                            <span class="key inline-flex rounded-sm  shadow-sm">
                                <a @click="setInput('2')" class="button button-blur button-wide">
                                    2
                                </a>
                            </span>
                            <span class="key inline-flex rounded-sm  shadow-sm">
                                <a @click="setInput('3')" class="button button-blur button-wide">
                                    3
                                </a>
                            </span>
                        </div>

                        <div class="grid grid-cols-3 mt-1 gap-1">
                            <span class="key inline-flex rounded-sm  shadow-sm">
                            </span>
                            <span class="key inline-flex rounded-sm  shadow-sm">
                                <a @click="setInput('0')" class="button button-blur button-wide">
                                    0
                                </a>
                            </span>
                            <span class="key inline-flex rounded-sm  shadow-sm">
                                <a @click="clear()" class="button button-blur button-wide">
                                    {{$t('translations.components.modal-manager-login.clear')}}
                                </a>
                            </span>
                        </div>

                        <div class="mt-6">
                            <button type="submit" class="group button button-filled button-wide button-large">
                                <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                                    <svg class="h-5 w-5 text-orange-200 group-hover:text-orange-100 transition ease-in-out duration-150" fill="currentColor" viewBox="0 0 20 20">
                                        <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
                                    </svg>
                                </span>
                                {{$t('translations.components.modal-manager-login.confirm')}}
                            </button>
                        </div>
                </form>
                </div>
                <div class="modal-footer px-4 py-4 sm:px-6 sm:flex sm:flex-row-reverse gap-5" v-if="cashfunction">
                    <span  class="mt-3 flex w-full rounded-sm  shadow-sm sm:mt-0 sm:w-auto">
                        <a @click="submit()" class="button button-filled inline-flex justify-center w-full rounded-sm  border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium shadow-sm focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                            {{$t('translations.components.modal-manager-login.confirm')}}
                        </a>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as _bcrypt from 'twin-bcrypt';

// components
import ModalClose from './ModalClose.vue';

export default {
    components: {
      ModalClose,
    },
    data () {
        return {
            error_auth: false,
            active: false,
            pincode: '',
        }
    },

    methods: {
        open() {
            if (this.terminal.role === 'manager') {
                this.login();
                return;
            }

            this.clear();
            this.active = true
        },

        close() {
            this.clear();
            this.active = false
        },

        setInput(value) {
            this.pincode += value
        },

        clear() {
            this.error_auth = false;
            this.pincode = ''
        },

        login() {
            this.close();
            this.$emit('loggedin');
        },

        onSubmit () {

            if (_bcrypt.compareSync(this.pincode, this.terminal.pincode_admin)) {
                this.login();
            } else {
                this.error_auth = true;
            }

        },
    },
	computed: {
		terminal() {
			return this.$store.getters['terminal/terminal'];
        },
		isMobile() {
			return this.$store.getters['terminal/isMobile'];
        },
    }
}
</script>
