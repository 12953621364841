import store from '../store'
import { add as addPrintjob } from '../lib/printerqueue';

export default class PrintReport {
    print(type, report) {
        // eslint-disable-next-line no-undef
        var builder = new StarWebPrintBuilder();

        // Create Request
        var request = '';

        request += builder.createInitializationElement();

        request += builder.createTextElement({ characterspace: 2 });
        request += builder.createAlignmentElement({ position: 'center' });
        request += builder.createTextElement({ emphasis: true });

        let self = this

        request += builder.createTextElement({ emphasis: true });

        if (type == 'zreport') {
            request += builder.createTextElement({ data: 'Z-rapportage' });
        } else if (type == 'xreport') {
            request += builder.createTextElement({ data: 'X-rapportage' });
        }
        request += builder.createTextElement({ emphasis: false });
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: report.meta.client + '\n' });
        request += builder.createTextElement({ data: report.meta.location + '\n' });
        request += builder.createTextElement({ data: 'Terminal: ' + report.meta.terminal + '\n' });
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: '\n' });
        request += builder.createAlignmentElement({ position: 'left' });

        if (type == 'zreport') {
            request += builder.createTextElement({ data: 'Boekdatum: ' + report.meta.bookingsdate + '\n' });
        }
        request += builder.createTextElement({ data: 'Afgedrukt: ' + report.meta.created_at + '\n' });
        // request += builder.createTextElement({ data: 'Afgedrukt: ' + report.meta.printed_at + '\n' });
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: '\n' });


        request += builder.createTextElement({ emphasis: true });
        request += builder.createTextElement({ data: 'Omzet' });
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: '\n', emphasis: false });

        // Revenue
        if (store.getters['printer/paperwidth'] == '58mm') {
            request += builder.createTextElement({
                data: self.format_string('Bruto', 15) + '  ' + self.format_string(parseFloat(report.revenue.bruto).toFixed(2), 8, 'right')
            });
            request += builder.createTextElement({ data: '\n' });
            request += builder.createTextElement({
                data: self.format_string('Netto', 15) + '  ' + self.format_string(parseFloat(report.revenue.netto).toFixed(2), 8, 'right')
            });
            request += builder.createTextElement({ data: '\n' });
        } else if (store.getters['printer/paperwidth'] == '82mm' || store.getters['printer/paperwidth'] == '72mm') {
            request += builder.createTextElement({
                data: self.format_string('Bruto', 17) + '  ' + self.format_string(parseFloat(report.revenue.bruto).toFixed(2), 8, 'right')
            });
            request += builder.createTextElement({ data: '\n' });
            request += builder.createTextElement({
                data: self.format_string('Netto', 17) + '  ' + self.format_string(parseFloat(report.revenue.netto).toFixed(2), 8, 'right')
            });
        }
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: '\n' });

        request += builder.createTextElement({ emphasis: true });
        request += builder.createTextElement({ data: 'Afrekenvormen' });
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: '\n' });

        // Paymentconditions
        if (store.getters['printer/paperwidth'] == '58mm') {
            request += builder.createTextElement({
                emphasis: true,
                data: self.format_string('OMSCHR', 9) + '  ' + self.format_string('AANT', 5) + '  ' + self.format_string('TOTAAL', 8, 'right')
            });
            request += builder.createTextElement({ data: '\n', emphasis: false });
            report.paymenttypes.forEach(function (line) {
                request += builder.createTextElement({
                    data: self.format_string(line.name, 9) + '  ' + self.format_string(parseFloat(line.quantity).toFixed(2), 5, 'right') + '  ' + self.format_string(parseFloat(line.total).toFixed(2), 8, 'right') + '\n'
                });
            });
        } else if (store.getters['printer/paperwidth'] == '82mm' || store.getters['printer/paperwidth'] == '72mm') {
            request += builder.createTextElement({
                emphasis: true,
                data: self.format_string('OMSCHR', 15) + '  ' + self.format_string('AANT', 5) + '  ' + self.format_string('TOTAAL', 8, 'right')
            });
            request += builder.createTextElement({ data: '\n', emphasis: false });
            report.paymenttypes.forEach(function (line) {
                request += builder.createTextElement({
                    data: self.format_string(line.name, 15) + '  ' + self.format_string(parseFloat(line.quantity).toFixed(2), 5, 'right') + '  ' + self.format_string(parseFloat(line.total).toFixed(2), 8, 'right') + '\n'
                });
            });
        }
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: '\n' });


        request += builder.createTextElement({ emphasis: true });
        request += builder.createTextElement({ data: 'Kasfuncties' });
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: '\n' });

        if (store.getters['printer/paperwidth'] == '58mm') {
            request += builder.createTextElement({
                emphasis: true,
                data: self.format_string('OMSCHR', 9) + '  ' + self.format_string('AANT', 5) + '  ' + self.format_string('TOTAAL', 8, 'right')
            });
            request += builder.createTextElement({ data: '\n', emphasis: false });
            report.cashfunctions.forEach(function (line) {
                request += builder.createTextElement({
                    data: self.format_string(line.description, 9) + '  ' + self.format_string(parseFloat(line.quantity).toFixed(2), 5, 'right') + '  ' + self.format_string(parseFloat(line.total).toFixed(2), 8, 'right') + '\n'
                });
            });
        } else if (store.getters['printer/paperwidth'] == '82mm' || store.getters['printer/paperwidth'] == '72mm') {
            request += builder.createTextElement({
                emphasis: true,
                data: self.format_string('OMSCHR', 15) + '  ' + self.format_string('AANT', 5) + '  ' + self.format_string('TOTAAL', 8, 'right')
            });
            request += builder.createTextElement({ data: '\n', emphasis: false });
            report.cashfunctions.forEach(function (line) {
                request += builder.createTextElement({
                    data: self.format_string(line.description, 15) + '  ' + self.format_string(parseFloat(line.quantity).toFixed(2), 5, 'right') + '  ' + self.format_string(parseFloat(line.total).toFixed(2), 8, 'right') + '\n'
                });
            });
        }
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: '\n' });

        // Taxes
        request += builder.createTextElement({ emphasis: true });
        request += builder.createTextElement({ data: 'BTW' });
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: '\n' });

         if (store.getters['printer/paperwidth'] == '58mm') {
            request += builder.createTextElement({
                emphasis: true,
                data: self.format_string('OMSCHR', 16) + '  ' + self.format_string('TOTAAL', 8, 'right')
            });
            request += builder.createTextElement({ data: '\n', emphasis: false });

            for(let taxrate in report.taxrates) {
                if (report.taxrates[taxrate].percentage > 0) {
                    request += builder.createTextElement({
                        data: self.format_string(report.taxrates[taxrate].percentage + '%', 16) + '  ' + self.format_string(parseFloat(report.taxrates[taxrate].taxes).toFixed(2), 8, 'right') + '\n'
                    });
                }
            }
        } else if (store.getters['printer/paperwidth'] == '82mm' || store.getters['printer/paperwidth'] == '72mm') {
            request += builder.createTextElement({
                emphasis: true,
                data: self.format_string('OMSCHR', 20) + '  ' + self.format_string('TOTAAL', 8, 'right')
            });
            request += builder.createTextElement({ data: '\n', emphasis: false });

            for(let taxrate in report.taxrates) {
                if (report.taxrates[taxrate].percentage > 0) {
                    request += builder.createTextElement({
                        data: self.format_string(report.taxrates[taxrate].percentage + '%', 20) + '  ' + self.format_string(parseFloat(report.taxrates[taxrate].taxes).toFixed(2), 8, 'right') + '\n'
                    });
                }
            }
        }
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: '\n' });

        // Kortingen
        request += builder.createTextElement({ emphasis: true });
        request += builder.createTextElement({ data: 'Kortingen' });
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: '\n' });

        if (store.getters['printer/paperwidth'] == '58mm') {
            request += builder.createTextElement({
                emphasis: true,
                data: self.format_string('OMSCHR', 9) + '  ' + self.format_string('AANT', 5) + '  ' + self.format_string('TOTAAL', 8, 'right')
            });
            request += builder.createTextElement({ data: '\n', emphasis: false });
            request += builder.createTextElement({
                data: self.format_string('Kortingen', 9) + '  ' + self.format_string(parseFloat(report.discounts.quantity).toFixed(2), 5, 'right') + '  ' + self.format_string(parseFloat(report.discounts.total).toFixed(2), 8, 'right') + '\n'
            });
        } else if (store.getters['printer/paperwidth'] == '82mm' || store.getters['printer/paperwidth'] == '72mm') {
            request += builder.createTextElement({
                emphasis: true,
                data: self.format_string('OMSCHR', 15) + '  ' + self.format_string('AANT', 5) + '  ' + self.format_string('TOTAAL', 8, 'right')
            });
            request += builder.createTextElement({
                data: self.format_string('Kortingen', 9) + '  ' + self.format_string(parseFloat(report.discounts.quantity).toFixed(2), 5, 'right') + '  ' + self.format_string(parseFloat(report.discounts.total).toFixed(2), 8, 'right') + '\n'
            });
            request += builder.createTextElement({ data: '\n', emphasis: false });
        }
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: '\n' });


        // Retouren
        request += builder.createTextElement({ emphasis: true });
        request += builder.createTextElement({ data: 'Retouren' });
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: '\n' });

        if (store.getters['printer/paperwidth'] == '58mm') {
            request += builder.createTextElement({
                emphasis: true,
                data: self.format_string('OMSCHR', 9) + '  ' + self.format_string('AANT', 5) + '  ' + self.format_string('TOTAAL', 8, 'right')
            });
            request += builder.createTextElement({ data: '\n', emphasis: false });
            request += builder.createTextElement({
                data: self.format_string('Retouren', 9) + '  ' + self.format_string(parseFloat(report.returns.quantity).toFixed(2), 5, 'right') + '  ' + self.format_string(parseFloat(report.returns.total).toFixed(2), 8, 'right') + '\n'
            });
        } else if (store.getters['printer/paperwidth'] == '82mm' || store.getters['printer/paperwidth'] == '72mm') {
            request += builder.createTextElement({
                emphasis: true,
                data: self.format_string('OMSCHR', 15) + '  ' + self.format_string('AANT', 5) + '  ' + self.format_string('TOTAAL', 8, 'right')
            });
            request += builder.createTextElement({
                data: self.format_string('Retouren', 9) + '  ' + self.format_string(parseFloat(report.returns.quantity).toFixed(2), 5, 'right') + '  ' + self.format_string(parseFloat(report.returns.total).toFixed(2), 8, 'right') + '\n'
            });
            request += builder.createTextElement({ data: '\n', emphasis: false });
        }
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: '\n' });

        // Departments
        request += builder.createTextElement({ emphasis: true });
        request += builder.createTextElement({ data: 'Hoofdgroepen' });
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: '\n' });

         if (store.getters['printer/paperwidth'] == '58mm') {
            request += builder.createTextElement({
                emphasis: true,
                data: self.format_string('OMSCHR', 16) + '  ' + self.format_string('TOTAAL', 8, 'right')
            });
            request += builder.createTextElement({ data: '\n', emphasis: false });

            for(let department in report.departments) {
                request += builder.createTextElement({
                    data: self.format_string(report.departments[department].name, 16) + '  ' + self.format_string(parseFloat(report.departments[department].total).toFixed(2), 8, 'right') + '\n'
                });
            }
        } else if (store.getters['printer/paperwidth'] == '82mm' || store.getters['printer/paperwidth'] == '72mm') {
            request += builder.createTextElement({
                emphasis: true,
                data: self.format_string('OMSCHR', 20) + '  ' + self.format_string('TOTAAL', 8, 'right')
            });
            request += builder.createTextElement({ data: '\n', emphasis: false });

            for(let department in report.departments) {
                request += builder.createTextElement({
                    data: self.format_string(report.departments[department].name, 20) + '  ' + self.format_string(parseFloat(report.departments[department].total).toFixed(2), 8, 'right') + '\n'
                });
            }
        }
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: '\n' });

        request += builder.createTextElement({
            data: '\n',
            emphasis: false
        });


        request += builder.createTextElement({ data: '\n' });

        // request += builder.createTextElement({
        //     emphasis: false
        // });
        // request += builder.createTextElement({ data: transaction.attributes.time + ' ' + transaction.attributes.date + '\n' });

        request += builder.createCutPaperElement({
            feed: true,
            type: 'full',//store.printer.autocutter,
        });

        // add to queue
        addPrintjob(request);

        return;
    }


    format_string(value, length, align = 'left') {
        let str = value.toString();

        while (str.length < length) {
            if (align == 'right') {
                str = ' ' + str;
            } else {
                str = str + ' ';
            }
        }
        if (str.length <= length) {
            return str
        }
        return str.slice(0, length)
        // return string;
    }
}
